'use strict'

angular
	.module('cb.core.api-service')
	.factory('ApiService', ["$http", "SessionService", "$modal", function ($http, SessionService, $modal) {
		var apiService = {

			// Reusable logic to make a GET request and return a promise
			promiseGet: function (url) {
				try {
					return $http.get(url);

				} catch (e) {
					console.error(e);
				}
			},

			// Reusable logic to make a POST request and return a promise
			promisePost: function (url, data, config = null) {
				try {
					if (config != null) {
						return $http.post(url, JSON.stringify(data), config);
					} else {
						return $http.post(url, JSON.stringify(data));

					}
				} catch (e) {
					console.error(e);
				}
			},

			promiseUpload: function (url, data, file) {
				try {
					var fd = new FormData();
					if (file != null) {
						fd.append('file', file);
					}
					for (var key in data) {
						if (moment.isMoment(data[key])) {
							fd.append(key, data[key]['_d']);
						} else if (typeof (data[key]) == 'object') {
							for (var k in data[key]) {
								if (moment.isMoment(data[key][k]))
									fd.append(key, data[key][k]['_d']);
								fd.append(key + "." + k, data[key][k]);
							};
						} else {
							fd.append(key, data[key]);
						}
					}

					return $http.post(url, fd, {
						transformRequest: angular.identity,
						responseType: 'arraybuffer',
						headers: {
							'Content-Type': undefined
						}
					});
				} catch (e) {
					console.error(e);
				}
			},

			promiseOpenModal: function (modalTemplate, Controller, items) {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: modalTemplate,
					controller: Controller,
					resolve: {
						items: function () {
							return items;
						}
					}
				});

				return modalInstance;
			},

			downloadFile: function (filename, mimeType, csvContent) {
				filename = filename.replace(/['"]+/g, '');
				var blob = new Blob([csvContent], {
					type: mimeType
				});

				var needsClick = true;

				if (navigator.appVersion.toString().indexOf('.NET') > 0)
					window.navigator.msSaveBlob(blob, filename);
				else if (window.URL) {
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", null, null);

					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
					//window.URL.revokeObjectURL(url);
				} else {

					if (document.createEvent) {
						var link = document.createElement("a");
						link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
						link.setAttribute("download", filename);
						var e = document.createEvent('MouseEvents');
						e.initEvent('click', true, true);
						needsClick = link.dispatchEvent(e);
					}

					if (needsClick) {
						window.navigator.msSaveBlob(blob, filename);
					}
				}
			}
		};
		return apiService;
	}]);
